/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-4/12 md:w-4/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Virtual Machine Information</h3>
            <div v-for="item of vm_info" class="vx-row mb-1" :key="item.id">
              <div class="vx-col flex flex-row items-center w-1/5 p-3">
                <font-awesome-icon icon="fa-sharp fa-solid fa-server" style="background: #181d2a" class="h-8 w-8 p-4 rounded-full" />
              </div>
              <div class="vx-col w-3/5 p-3">
                <span class="text-xl font-bold my-1 flex flex-row gap-x-2"
                  >VM NAME:
                  <p class="font-medium">{{ item.name }}</p></span
                >
                <span class="text-xl font-bold my-1 flex flex-row gap-x-2"
                  >IP:
                  <p class="font-medium">{{ item.ip }}</p></span
                >
              </div>
              <div class="vx-col flex w-1/5 p-3 items-center">
                <vs-switch v-model="item.value" color="success" />
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col lg:w-8/12 md:w-8/12 sm:w-5/5">
          <div style="height: 35rem">
            <vue-command
              id="command_prompt"
              :commands="commands"
              :prompt="'C:\\Users\\' + activeUserInfo.firstName.toLowerCase().replace(/ /g, '') + '>'"
              :show-intro="true"
              class=""
              intro="Microsoft Windows [Version 10.0.18363.1256] (c) 2019 Microsoft Corporation. All rights reserved."
              style="background-color: #111111; border: 1px solid #181d2a; border-radius: 0.5rem"
              title="Command Prompt"
            />
          </div>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import VueCommand, { createStdout, createStderr } from 'vue-command';
import 'vue-command/dist/vue-command.css';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      vm_info: [
        {
          name: 'MS1',
          ip: '10.1.0.1',
          value: false,
          answer: true,
        },
        {
          name: 'DC1',
          ip: '10.1.0.2',
          value: false,
          answer: true,
        },
        {
          name: 'LX1',
          ip: '10.1.0.3',
          value: false,
          answer: false,
        },
        {
          name: 'KLX1',
          ip: '10.1.0.4',
          value: false,
          answer: true,
        },
        {
          name: 'NTNX',
          ip: '10.1.0.5',
          value: false,
          answer: true,
        },
        {
          name: 'PKT',
          ip: '10.1.0.6',
          value: false,
          answer: false,
        },
        {
          name: 'PKT',
          ip: '10.1.0.7',
          value: false,
          answer: false,
        },
      ],

      commands: {
        mema: () => createStdout('Is the best developer in the world'),
        help: () => createStdout('Available commands:<br>&nbsp;ping [args] [target]<br>'),
        ping(x) {
          if (x._[1] === undefined) {
            return createStderr('Invalid Usage: ping [args] [target]');
          }

          if (x._[1] === '10.1.0.1') {
            return createStderr(`<br>-----------------------------------------------<br>
Pinging 10.1.0.1 with 32 bytes of data:<br>
Reply from 10.1.0.1: bytes=32 time<1ms TTL=64<br>
Reply from 10.1.0.1: bytes=32 time<1ms TTL=64<br>
Reply from 10.1.0.1: bytes=32 time<1ms TTL=64<br>
Reply from 10.1.0.1: bytes=32 time<1ms TTL=64<br>
<br>
Ping statistics for 10.1.0.1:<br>
    Packets: Sent = 4, Received = 4, Lost = 0 (0% loss),<br>
Approximate round trip times in milli-seconds:<br>
    Minimum = 0ms, Maximum = 0ms, Average = 0ms<br>
-----------------------------------------------<br><br>`);
          }

          if (x._[1] === '10.1.0.2') {
            return createStderr(`<br>-----------------------------------------------<br>
Pinging 10.1.0.2 with 32 bytes of data:<br>
Reply from 10.1.0.2: bytes=32 time<1ms TTL=64<br>
Reply from 10.1.0.2: bytes=32 time<1ms TTL=64<br>
Reply from 10.1.0.2: bytes=32 time<1ms TTL=64<br>
Reply from 10.1.0.2: bytes=32 time<1ms TTL=64<br>
<br>
Ping statistics for 10.1.0.2:<br>
    Packets: Sent = 4, Received = 4, Lost = 0 (0% loss),<br>
Approximate round trip times in milli-seconds:<br>
    Minimum = 0ms, Maximum = 0ms, Average = 0ms<br>
-----------------------------------------------<br><br>`);
          }

          if (x._[1] === '10.1.0.3') {
            return createStderr(`<br>-----------------------------------------------<br>
Pinging 10.1.0.3 with 32 bytes of data:<br>
Request timed out.<br>
<br>
Ping statistics for 10.1.0.3:<br>
    Packets: Sent = 1, Received = 0, Lost = 1 (100% loss),<br>
-----------------------------------------------<br><br>`);
          }

          if (x._[1] === '10.1.0.4') {
            return createStderr(`<br>-----------------------------------------------<br>
Pinging 10.1.0.4 with 32 bytes of data:<br>
Reply from 10.1.0.4: bytes=32 time<1ms TTL=64<br>
Reply from 10.1.0.4: bytes=32 time<1ms TTL=64<br>
Reply from 10.1.0.4: bytes=32 time<1ms TTL=64<br>
Reply from 10.1.0.4: bytes=32 time<1ms TTL=64<br>
<br>
Ping statistics for 10.1.0.4:<br>
    Packets: Sent = 4, Received = 4, Lost = 0 (0% loss),<br>
Approximate round trip times in milli-seconds:<br>
    Minimum = 0ms, Maximum = 0ms, Average = 0ms<br>
-----------------------------------------------<br><br>`);
          }

          if (x._[1] === '10.1.0.5') {
            return createStderr(`<br>-----------------------------------------------<br>
Pinging 10.1.0.5 with 32 bytes of data:<br>
Reply from 10.1.0.5: bytes=32 time<1ms TTL=64<br>
Reply from 10.1.0.5: bytes=32 time<1ms TTL=64<br>
Reply from 10.1.0.5: bytes=32 time<1ms TTL=64<br>
Reply from 10.1.0.5: bytes=32 time<1ms TTL=64<br>
<br>
Ping statistics for 10.1.0.5:<br>
    Packets: Sent = 4, Received = 4, Lost = 0 (0% loss),<br>
Approximate round trip times in milli-seconds:<br>
    Minimum = 0ms, Maximum = 0ms, Average = 0ms<br>
-----------------------------------------------<br><br>`);
          }

          if (x._[1] === '10.1.0.6') {
            return createStderr(`<br>-----------------------------------------------<br>
Pinging 10.1.0.6 with 32 bytes of data:<br>
Request timed out.<br>
<br>
Ping statistics for 10.1.0.6:<br>
    Packets: Sent = 1, Received = 0, Lost = 1 (100% loss),<br>
-----------------------------------------------<br><br>`);
          }

          if (x._[1] === '10.1.0.7') {
            return createStderr(`<br>-----------------------------------------------<br>
Pinging 10.1.0.7 with 32 bytes of data:<br>
Request timed out.<br>
<br>
Ping statistics for 10.1.0.7:<br>
    Packets: Sent = 1, Received = 0, Lost = 1 (100% loss),<br>
-----------------------------------------------<br><br>`);
          }

          return createStdout('Invalid IP');
        },
      },
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;
      let simulation_bad = false;

      this.vm_info.forEach((x) => {
        if (x.value === x.answer && x.answer) {
          totalScore++;
        }
        if (x.value !== x.answer && !x.answer) {
          simulation_bad = true;
        }
      });

      if (simulation_bad) {
        this.$emit('simulation_getting_there', 0, false);
        return this.$vs.dialog({
          color: 'danger',
          title: 'Invalid VMs Selected!',
          text: "You selected some of the VMs that weren't supposed to be checked, try again.",
        });
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    VueCommand,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
